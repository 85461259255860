<template>
  <div
    class="sidebar-menu"
    :class="{
      'sidebar-menu--expanded': isExpanded
    }"
  >
    <DatasourceConnectionModal />
    <SpreadsheetConnectorModal :id="modalsId.SPREADSHEET_CONNECTOR" />
    <SpreadsheetUpdateDataModal :id="modalsId.SPREADSHEET_UPDATE_DATA" />
    <BC365ConnectorModal :id="modalsId.BC365_CONNECTOR" />
    <DatabaseConnectorModal :id="modalsId.DATABASE_CONNECTOR" />
    <NetsuiteConnectorModal :id="modalsId.NETSUITE_CONNECTOR" />
    <SkubanaConnectorModal :id="modalsId.SKUBANA_CONNECTOR" />
    <DynamicsNavConnectorModal :id="modalsId.DYNAMICS_NAV_CONNECTOR" />
    <FishbowlConnectorModal :id="modalsId.FISHBOWL_CONNECTOR" />
    <SpireConnectorModal :id="modalsId.SPIRE_CONNECTOR" />
    <QBDesktopConnectorModal :id="modalsId.QB_DESKTOP_CONNECTOR" />
    <QBLoadingModal />
    <QBOnlineConnectorModal :id="modalsId.QB_ONLINE_CONNECTOR" />
    <SapConnectorModal :id="modalsId.SAP_CONNECTOR" />
    <DynamicsGpConnectorModal :id="modalsId.DYNAMICS_GP_CONNECTOR" />
    <OdooConnectorModal :id="modalsId.ODOO_CONNECTOR" />

    <SystemDetailsModal :id="modalsId.SYSTEM_DETAILS" />
    <ProjectStatsModal :id="modalsId.PROJECT_STATS" />
    <ReportIssueModal :id="modalsId.REPORT_ISSUE" />
    <KeyboardShortcutsModal :id="modalsId.KEYBOARD_SHORTCUTS" />
    <TimeMachineOverlay v-if="isTimeMachineOverlayVisible" />
    <LanguageUiModal :id="modalsId.LANGUAGE_UI" />
    <div class="sidebar-menu__top">
      <div
        v-tooltip.right="tooltipData(!isExpanded && $t('Web.Sidebar.Expand'))"
        class="sidebar-menu__header"
        @click.stop="toggleSidebar(true)"
      >
        <icon
          :data="require(`@sidebar/logo.svg`)"
          class="sidebar-menu__header--logo"
        />
        <div class="sidebar-menu__header--expand">
          <icon
            :data="require(`@sidebar/expand.svg`)"
            class="size-16 color-grey-20"
          />
        </div>
        <transition name="fade">
          <div
            v-if="isExpanded"
            class="sidebar-menu__header-additional"
          >
            <icon
              :data="require(`@sidebar/logo_text.svg`)"
              class="sidebar-menu__header--logo-text"
            />
            <div class="sidebar-menu__header--collapse">
              <icon
                v-tooltip.right="tooltipData(isExpanded && $t('Web.Sidebar.Collapse'), true)"
                :data="require(`@sidebar/collapse.svg`)"
                class="size-16 color-grey-20"
                @click.stop="toggleSidebar(false)"
              />
            </div>
          </div>
        </transition>
      </div>
      <div
        v-if="$sl_hasProjectAccess"
        class="sidebar-menu__links"
      >
        <router-link
          v-for="link in visibleLinks"
          :key="link.name"
          exact-path
          :to="{
            name: link.name,
            params: {
              projectId
            }
          }"
          class="sidebar-menu__router-link"
          :class="{
            'sidebar-menu__router-link--active': $sl_routeName === link.name
          }"
        >
          <div
            v-tooltip.right="tooltipData(!isExpanded && link.label)"
            class="sidebar-menu__button"
          >
            <icon :data="require(`@sidebar/${link.icon}.svg`)" />
            <transition name="fade">
              <span
                v-if="isExpanded"
                class="sidebar-menu__button-title"
              >
                {{ link.label }}
              </span>
            </transition>
          </div>
        </router-link>
        <!--router-link
          exact-path
          to="/uikit"
          class="sidebar-menu__router-link"
        >
          <div
            v-tooltip.right="tooltipData(!isExpanded && 'UI')"
            class="sidebar-menu__button"
          >
            <icon :data="require(`@sidebar/uikit.svg`)" />
            <transition name="fade">
              <span
                v-if="isExpanded"
                class="sidebar-menu__button-title"
              >
                UI
              </span>
            </transition>
          </div>
        </router-link-->
      </div>
    </div>
    <div class="sidebar-menu__bottom">
      <ProjectSwitcherDropdown
        v-if="hasProjects"
        :expanded="isExpanded"
      >
        <template #target="{ isShown }">
          <div
            v-tooltip.right="tooltipData(!isShown && !isExpanded && $t('Web.Sidebar.ProjectSelection'))"
            class="sidebar-menu__button"
          >
            <icon data="@sidebar/project-switch.svg" />
            <transition name="fade">
              <span
                v-if="isExpanded"
                class="sidebar-menu__button-title"
              >
                {{ $t('Web.Sidebar.ProjectSelection') }}
              </span>
            </transition>
          </div>
        </template>
      </ProjectSwitcherDropdown>
      <UpdateDropdown
        v-if="!isTimeMachineActive && $sl_hasProjectAccess && !$sl_isEditingRoute"
        :expanded="isExpanded"
      >
        <template #target="{ isShown }">
          <div
            v-tooltip.right="tooltipData(!isShown && !isExpanded && $t('Main.Ui.acReload'))"
            class="sidebar-menu__button"
          >
            <icon
              data="@sidebar/update.svg"
              :class="{
                'spin-reverse': isProjectUpdating
              }"
            />
            <transition name="fade">
              <span
                v-if="isExpanded"
                class="sidebar-menu__button-title"
              >
                {{ $t('Main.Ui.acReload') }}
              </span>
            </transition>
          </div>
        </template>
      </UpdateDropdown>
      <TimeMachineDropdown
        v-if="isTimeMachineActive"
        mount-show
      >
        <template #target="{ isShown }">
          <div
            v-tooltip.right="tooltipData(!isShown && !isExpanded && $t('Main.Ui.acStartSimulation'))"
            class="sidebar-menu__button"
          >
            <icon
              class="stroke-icon--white"
              :data="require('@sidebar/time-machine.svg')"
            />
            <transition name="fade">
              <span
                v-if="isExpanded"
                class="sidebar-menu__button-title"
              >
                {{ $t('Main.Ui.acStartSimulation') }}
              </span>
            </transition>
          </div>
        </template>
      </TimeMachineDropdown>
      <ProjectDropdown
        v-if="$sl_hasProjectAccess"
        :expanded="isExpanded"
      >
        <template #target="{ isShown }">
          <div
            v-tooltip.right="tooltipData(!isShown && !isExpanded && $t('Settings.TabNames.project'))"
            class="sidebar-menu__button"
          >
            <icon :data="require('@sidebar/project.svg')" />
            <transition name="fade">
              <span
                v-if="isExpanded"
                class="sidebar-menu__button-title"
              >
                {{ $t('Settings.TabNames.project') }}
              </span>
            </transition>
          </div>
        </template>
      </ProjectDropdown>
      <UserDropdown :expanded="isExpanded">
        <template #target>
          <div class="sidebar-menu__button sidebar-menu__bottom--user">
            <div
              class="sidebar-menu__user"
              :class="{
                'sidebar-menu__user--collapsed': !isExpanded,
                'sidebar-menu__user--license-details': needLicenseDetails,
                'sidebar-menu__user--license-expired': isExpired
              }"
            >
              <SlAvatar
                :url="avatarUrl"
                :name="$sl_userName"
              />
              <div class="sidebar-menu__user-content">
                <div class="sidebar-menu__user-title">
                  <span>
                    {{ $sl_userName }}
                  </span>
                </div>
                <div class="sidebar-menu__user-description">
                  {{ userRole }}
                </div>
              </div>
            </div>
          </div>
        </template>
      </UserDropdown>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import UpdateDropdown from '@/components/SidebarMenu/Dropdown/UpdateDropdown.vue';
import ProjectDropdown from '@/components/SidebarMenu/Dropdown/ProjectDropdown.vue';
import UserDropdown from '@/components/SidebarMenu/Dropdown/UserDropdown.vue';
import ProjectSwitcherDropdown from '@/components/SidebarMenu/Dropdown/ProjectSwitcherDropdown.vue';
import TimeMachineDropdown from '@/components/SidebarMenu/Dropdown/TimeMachineDropdown.vue';
import TimeMachineOverlay from '@/components/SidebarMenu/TimeMachineOverlay.vue';
import SpreadsheetConnectorModal from '@/components/Modals/Connections/Connectors/Spreadsheet/SpreadsheetConnectorModal';
import SpreadsheetUpdateDataModal from '@/components/Modals/Connections/Connectors/Spreadsheet/SpreadsheetUpdateDataModal';
import BC365ConnectorModal from '@/components/Modals/Connections/Connectors/BC365/BC365ConnectorModal';
import DatabaseConnectorModal from '@/components/Modals/Connections/Connectors/Database/DatabaseConnectorModal';
import NetsuiteConnectorModal from '@/components/Modals/Connections/Connectors/Netuite/NetsuiteConnectorModal';
import SkubanaConnectorModal from '@/components/Modals/Connections/Connectors/Skubana/SkubanaConnectorModal';
import DynamicsNavConnectorModal from '@/components/Modals/Connections/Connectors/DynamicsNav/DynamicsNavConnectorModal';
import SpireConnectorModal from '@/components/Modals/Connections/Connectors/Spire/SpireConnectorModal';
import QBDesktopConnectorModal from '@/components/Modals/Connections/Connectors/QB/QBDesktopModal';
import QBOnlineConnectorModal from '@/components/Modals/Connections/Connectors/QBO/QBOnlineModal';
import SapConnectorModal from '@/components/Modals/Connections/Connectors/Sap/SapConnectorModal';
import FishbowlConnectorModal from '@/components/Modals/Connections/Connectors/Fisbowl/FishbowlConnectorModal';
import DynamicsGpConnectorModal from '@/components/Modals/Connections/Connectors/DynamicsGp/DynamicsGpConnectorModal';
import OdooConnectorModal from '@/components/Modals/Connections/Connectors/Odoo/OdooConnectorModal';
import SystemDetailsModal from '@/components/Modals/SystemDetailsModal';
import QBLoadingModal from '@/components/Modals/Connections/Connectors/QB/QBLoadingModal.vue';
import ReportIssueModal from '@/components/Modals/ReportIssueModal';
import KeyboardShortcutsModal from '@/components/Modals/KeyboardShortcutsModal';
import LanguageUiModal from '@/components/Modals/LanguageUiModal';
import ProjectStatsModal from '@/components/Modals/ProjectStatsModal';
import DatasourceConnectionModal from '@/components/Modals/Connections/Connectors/DatasourceConnectionModal.vue';
import { modal } from '@/mixins/modal';
import { access } from '@/mixins/access';
import { linkItemsConfig } from '@/config/sidebar/sidebar.config';
import modalsId from '@/config/shared/modalsId.config';

export default {
  name: 'SidebarMenu',
  components: {
    LanguageUiModal,
    KeyboardShortcutsModal,
    SpreadsheetUpdateDataModal,
    OdooConnectorModal,
    DynamicsGpConnectorModal,
    FishbowlConnectorModal,
    SapConnectorModal,
    DynamicsNavConnectorModal,
    SpireConnectorModal,
    QBDesktopConnectorModal,
    QBOnlineConnectorModal,
    SystemDetailsModal,
    SpreadsheetConnectorModal,
    BC365ConnectorModal,
    DatabaseConnectorModal,
    NetsuiteConnectorModal,
    SkubanaConnectorModal,
    QBLoadingModal,
    UpdateDropdown,
    ProjectDropdown,
    ProjectSwitcherDropdown,
    TimeMachineDropdown,
    TimeMachineOverlay,
    UserDropdown,
    ReportIssueModal,
    ProjectStatsModal,
    DatasourceConnectionModal
  },
  mixins: [modal, access],
  data() {
    return {
      modalsId,
      isRedirecting: false,
      linkItems: linkItemsConfig(this)
    };
  },
  computed: {
    ...mapState({
      projectId: (state) => state.manageProjects.projectId,
      projectList: (state) => state.manageProjects.projectList,
      project: (state) => state.project.project,
      isProjectUpdating: (state) => state.project.isUpdating,
      isExpanded: (state) => state.sidebar,
      isTimeMachineOverlayVisible: (state) => state.timeMachine.isOverlayVisible
    }),
    ...mapGetters({
      needLicenseDetails: 'initialization/needLicenseDetails',
      isExpired: 'initialization/isExpired',
      isTimeMachineActive: 'manageProjects/isTimeMachineActive',
      userRole: 'user/userRole',
      availableProjectTabs: 'access/availableProjectTabs'
    }),
    hasProjects() {
      return this.projectList?.permanent?.length || this.projectList?.temporary?.length;
    },
    avatarUrl() {
      return this.authState?.user?.profilePictureUrl;
    },
    visibleLinks() {
      return this.availableProjectTabs?.reduce((acc, tab) => {
        if (this.linkItems[tab]) {
          acc.push(this.linkItems[tab]);
        }

        return acc;
      }, []);
    }
  },
  methods: {
    toggleSidebar(value) {
      this.$store.dispatch('setSidebar', value);
    },
    tooltipData(content, collapse) {
      return {
        content,
        class: 'sidebar-menu-tooltip',
        distance: collapse ? 20 : 5
      };
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/sidebar-menu/index.scss";
</style>
