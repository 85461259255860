<template>
  <div class="spreadsheet__files-upload">
    <SlControl
      v-model="filesSourceModel"
      :options="fileSourceOptions"
    />
    <ValidationObserver
      v-show="isSourceFile"
      ref="dropzoneObserver"
    >
      <SlValidate
        v-slot="{ invalid, validate }"
        vid="file"
        :rules="{
          required: true,
          sl_file_type: ['.xlsx', '.xls', '.csv'],
          sl_file_size: true,
          sl_file_duplicate: uploadedFileNames
        }"
        :message="!files.length"
      >
        <SlDropzone
          ref="dropzone"
          :accepted="spreadsheetAccepted"
          :max-files="remainFiles"
          :tooltip-max-files="MAX_FILES"
          :upload-callback="uploadFile"
          :validator="validate"
          :is-invalid="invalid"
          @queue-upload-start="emitUploading(true)"
          @queue-upload-end="emitUploading(false)"
          @upload-success="handleFileUploaded"
          @file-removed="handleRemoveFile"
        >
          <template #accepted>
            <div>
              {{ $t('Web.FileUpload.SupportedFormat', { 1: $t('Web.Spreadsheet.UploadAccepted') }) }}
            </div>
            <div>
              {{ $t('Web.FileUpload.MaxFileSize', { 1: '500MB' }) }}
            </div>
          </template>
        </SlDropzone>
      </SlValidate>
    </ValidationObserver>
    <ValidationObserver
      v-show="!isSourceFile"
      ref="linksObserver"
      class="spreadsheet__upload-link"
    >
      <transition name="fade">
        <SlAlert
          v-if="linkAlert"
          type="error"
          :accent="$t('Web.Spreadsheet.AlertNoUploadedLinks')"
        />
      </transition>
      <SlValidate
        v-for="(file, index) in filesUrl"
        :key="file.id"
        v-slot="{ invalid, validate, reset }"
        :vid="file.id"
        :rules="{
          'required': !isSourceFile,
          'sl_url': {
            message: file.error
          }
        }"
      >
        <SlUploadLink
          :value="file.url"
          :is-invalid="invalid"
          :validator="validate"
          :upload-callback="uploadFileWithURL"
          @upload-start="emitUploading(true)"
          @upload-end="emitUploading(false)"
          @upload-success="(value) => handleFileUrlUploaded({ ...value, index })"
          @upload-error="(error) => handleFileUrlUploadError({ error, index })"
          @input="(value) => handleUpdateFileUrl({ value, index })"
          @remove="(cb) => handleRemoveFileUrl({ index, reset, cb })"
        />
      </SlValidate>
      <span
        v-tooltip="getTooltip({
          content: $t('Web.FileUpload.TooltipMaxFiles', { 1: MAX_FILES }),
          disabled: filesUrl.length < remainFiles
        })"
        class="spreadsheet__upload-link-btn"
      >
        <SlButton
          variant="secondary"
          color="grey"
          :disabled="filesUrl.length >= remainFiles"
          inline
          @click="handleAddFileUrl"
        >
          <template #prepend>
            <icon
              data="@icons/plus.svg"
              class="size-16"
            />
          </template>
          {{ $t('Web.FileUpload.AddUrl') }}
        </SlButton>
      </span>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import FileUploadAdapter from '@/adapters/response/connection/spreadsheet/file/FileUpload.adapter';
import { fileSourceOptions, fileSourceTypes } from '@/config/connection/spreadsheet.config';
import { spreadsheetAccepted } from '@/config/utils/fileUpload.config';
import { MAX_FILES } from '@/config/connection/spreadsheet.config';
import { uuId } from '@/helpers/shared/uuId';
import { getTooltip } from '@/helpers/shared/tooltip';

export default {
  name: 'FilesUpload',
  props: {
    linkAlert: Boolean
  },
  data() {
    return {
      MAX_FILES,
      spreadsheetAccepted,
      filesSourceModel: fileSourceTypes.FILE,
      files: [],
      filesUrl: [this.getFileUrlItem()],
      getTooltip
    };
  },
  computed: {
    ...mapState({
      filesSource: state => state.spreadsheet.files_source,
      storeFiles: state => state.spreadsheet.files
    }),
    isSourceFile() {
      return this.filesSourceModel === fileSourceTypes.FILE;
    },
    fileSourceOptions() {
      const options = fileSourceOptions(this);

      if (this.storeFiles.length) {
        return options.map(option => ({
          ...option,
          disabled: this.filesSource !== option.value,
          tooltip: this.getTooltip({
            content: this.$t('Web.Spreadsheet.TooltipUploadMethod'),
            disabled: this.filesSource === option.value
          })
        }));
      }

      return options;
    },
    remainFiles() {
      return MAX_FILES - this.storeFiles.length;
    },
    uploadedFileNames() {
      return this.storeFiles.map(file => file.name);
    }
  },
  created() {
    this.filesSourceModel = this.filesSource || fileSourceTypes.FILE;
  },
  methods: {
    ...mapActions({
      uploadFile: 'spreadsheet/uploadFile',
      uploadFileWithURL: 'spreadsheet/uploadFileWithURL'
    }),
    emitUploading(isUploading) {
      this.$emit('loading', isUploading);
    },
    handleFileUploaded({ file, response }) {
      this.files.push(FileUploadAdapter(response?.data, file.upload.uuid));
    },
    handleRemoveFile(file) {
      this.files = this.files.filter(item => item.id !== file.upload.uuid);
    },
    getFileUrlItem() {
      return {
        id: uuId(),
        fileId: null,
        url: ''
      };
    },
    checkSameUrl(value) {
      const sameLocal = this.filesUrl.some(file => file.url === value);
      const sameStored = this.storeFiles.some(file => file.url === value || value.includes(file.name));

      return sameLocal || sameStored;
    },
    handleFileUrlUploaded({ index, response }) {
      const fileInfo = response?.data;

      this.$emit('link-upload-success');

      this.$set(this.filesUrl, index, {
        ...this.filesUrl[index],
        ...FileUploadAdapter(fileInfo)
      });
    },
    handleFileUrlUploadError({ index, error }) {
      this.$set(this.filesUrl, index, {
        ...this.filesUrl[index],
        error: error?.message
      });
    },
    handleUpdateFileUrl({ value, index }) {
      this.$set(this.filesUrl, index, {
        ...this.filesUrl[index],
        url: value,
        error: this.checkSameUrl(value)
          ? this.$t('Web.FileUpload.UrlAlreadyExist')
          : ''
      });
    },
    handleAddFileUrl() {
      this.filesUrl.push(this.getFileUrlItem());
    },
    handleRemoveFileUrl({ index, reset, cb }) {
      const newItems = this.filesUrl.length > 1 ? [] : [this.getFileUrlItem()];

      this.filesUrl.splice(index, 1, ...newItems);

      reset && reset();
      cb && cb();
    },
    clearEmptyFiles() {
      const filled = this.filesUrl.filter(item => item.fileId || item.url);

      this.filesUrl = filled.length ? filled : [this.getFileUrlItem()];
    },
    getSubmitFiles() {
      const files = this.isSourceFile ? this.files : this.filesUrl;

      return files.filter(item => item.fileId);
    },
    async submitData() {
      this.clearEmptyFiles();

      await this.$nextTick();

      return {
        fileSource: this.filesSourceModel,
        files: this.getSubmitFiles()
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.spreadsheet__files-upload {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.spreadsheet__upload-link {
  display: flex;
  flex-direction: column;
  row-gap: 8px;

  &-btn {
    width: fit-content;
    margin-top: 4px;
  }
}
</style>
